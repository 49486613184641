/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 5; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */
.language-console{
    pre {
        white-space: pre-wrap;
    }
}
